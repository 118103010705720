import React, { Component } from "react";
import axios from "axios";
import { configData } from "./configData/configData";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActions, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import './ListaFormBackoffice.css';
import "./font/Ubuntu-Bold.ttf";
import "./font/Ubuntu-Light.ttf";
import logoValuetech from "./image/logoEuroform.png";
import { Backdrop, CircularProgress } from "@mui/material";

axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

class ListaFormBackoffice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dati: [],
            loading: true,
        };
    }

    loadData() {
        var token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
            axios.get(configData.API_BASE + "/api/forms/").then(
                res => {
                    this.setState({ ...this.state, dati: res.data, loading: false });
                }
            );
        } else {
            axios.defaults.headers.common['Authorization'] = null;
        }
    }

    componentDidMount() {
        this.loadData();
    }

    // Metodo per modificare la visibilità del form
    toggleVisibility = (formId, currentVisibility) => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
            const newVisibility = !currentVisibility; // Toggle visibile
            axios.put(`${configData.API_BASE}/api/forms/${formId}/visibility`, { visibile: newVisibility })
                .then(res => {
                    // Se la risposta è positiva, aggiorniamo lo stato del form
                    this.setState(prevState => ({
                        dati: prevState.dati.map(form =>
                            form.id === formId ? { ...form, visibile: newVisibility } : form
                        )
                    }));
                    console.log(`Stato del form aggiornato a visibile: ${newVisibility}`);
                })
                .catch(error => {
                    console.error("Errore durante l'aggiornamento dello stato del form:", error);
                });
        }
    };

    render() {
        return (
            <div>
                <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                    <img src={logoValuetech} width={'20%'} height={'20%'} style={{ marginTop: "35px" }} />
                    <h1 style={{ marginTop: '20px', fontFamily: 'Ubuntu-Black', color: '#1d659c' }}>Lista Form per Backoffice</h1>
                    {this.state.dati.map((form) => (
                        <Grid item md={2.4} key={form.id}>
                            <Card className='card' style={{ fontFamily: 'Ubuntu-Light' }}>
                                <CardContent>
                                    <b>{form.nomeProgetto}</b>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'center' }}>
                                    <div>
                                        <Link component={Link} to={`/tab/${form.id}`} state={{ nome: form.nomeProgetto }}>
                                            <Button className='visualizzaButton' size="small">Mostra tabella delle Risposte</Button>
                                        </Link>
                                        {/* Aggiungi il pulsante per alternare la visibilità */}
    <Button
    className='visualizzaButton'
    size="small"
    color={form.visibile ? "secondary" : "primary"}
    onClick={() => this.toggleVisibility(form.id, form.visibile)}
    sx={{
        color: 'black', // Colore del testo
        backgroundColor: 'white', // Sfondo bianco
        border: 'none', // Rimuove il bordo
        //fontWeight: 'bold',
        padding: '3px 10px', // Padding per una comoda interazione
        textTransform: 'none', // Mantiene il testo in formato normale
        boxShadow: 'none', // Rimuove l'ombra
        transition: 'all 0.3s ease', // Transizione fluida per hover
        '&:hover': {
            color: 'white', // Colore del testo al passaggio del mouse
            backgroundColor: 'cornflowerblue', // Sfondo al passaggio del mouse
            border: 'none', // Rimuove il bordo anche al passaggio del mouse
        },
        marginLeft: '20px', // Distanza tra i pulsanti
    }}
>
    {form.visibile ? "NASCONDI FORM" : "MOSTRA FORM"}
</Button>






                                    </div>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                    <Backdrop sx={{ color: '#ffffff', zIndex: 999 }} open={this.state.loading}><CircularProgress color="inherit" /></Backdrop>
                </Grid>
            </div>
        );
    }
}

export default ListaFormBackoffice;
